//React
import React from 'react'
//Context
import { useDashboard } from '../contexts/DashboardContext'

//Components
import DashboardListItems from './SidebarListItems'

//MUI
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Dialog from '@mui/material/Dialog'
import Fade from '@mui/material/Fade'

//Icons
import * as AiIcons from 'react-icons/ai'

//Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />
})

export default function NavMenuModal() {
    const { toggleNavMenu, isOpenNavMenu, setOpenNavMenu } = useDashboard()

    return (
        <div>
            <Dialog
                fullScreen
                open={isOpenNavMenu}
                onClose={toggleNavMenu}
                TransitionComponent={Transition}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setOpenNavMenu(false)
                        }}
                    >
                        <AiIcons.AiOutlineClose />
                    </IconButton>
                </Toolbar>
                <List>
                    <DashboardListItems />
                </List>
            </Dialog>
        </div>
    )
}
