//React
import React, { useEffect } from 'react'
//MUI
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useDashboard } from '../contexts/DashboardContext'
import * as FiIcons from 'react-icons/fi'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import Offer from '../components/Offers/Offer';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup';

// Components
import { getFunctions, httpsCallable } from 'firebase/functions'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Earn() {
    const [offerList, setOfferList] = React.useState([])
    const { selectedBrand, setNavigationComponent } = useDashboard();
    const [loopStop, setLoopStop] = React.useState(false);

    const [openInfo, setOpenInfo] = React.useState(true);
    const handleOpenInfo = () => {setOpenInfo(true);};
    const handleCloseInfo = () => {setOpenInfo(false);};

    async function fetchOffers() {
        setOfferList([]);
        console.log(selectedBrand.id);
        const formJSON = {
            "brandId": selectedBrand.id
        }

        const functions = getFunctions();
        const GetOffersForBrand = httpsCallable(functions, 'GetOffersForBrand');
        try {
            await GetOffersForBrand({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    const offers = [];
                    const tmp = JSON.parse(result.data);
                    tmp.forEach(function (obj) {
                        offers.push(obj);
                    });
                    if(offers.length === 0){
                        console.log("no current offers need to regenerate")
                        archiveAndRegenerateOffers();
                    }else{
                        console.log(offers.length)
                        setOfferList(offers);
                        return true;
                    } 
                })
        } catch {
            console.log("Error getting Campaigns.");
        }
    }

    async function archiveAndRegenerateOffers() {
        const functions = getFunctions()
        const CreateAllUserOffers = httpsCallable(functions, 'CreateAllUserOffers')
        const ArchiveCurrentOffers = httpsCallable(functions, 'ArchiveCurrentOffers')

        const formJSON = {
            "brandId": selectedBrand.id
        }

        try {
            await ArchiveCurrentOffers({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                    return CreateAllUserOffers({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                        if(!loopStop){
                            fetchOffers();
                            setLoopStop(true);
                        }
                       
                    });
                });
           
        } catch (error) {
            console.log('Error data.')
        }
       
    }

    useEffect(() => {
        fetchOffers()
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <Toolbar>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setNavigationComponent('brandDashboard')
                            }}
                        >
                            <FiIcons.FiChevronLeft />
                        </IconButton>
                    </Toolbar>
                    <div align="center">
                        <img src={selectedBrand.logoURL} style={{ width: 80, height: 80 }} />
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Earn Zellit Points for {selectedBrand.name}
                        </Typography>

                        <Typography component="h1" variant="overline" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Current Live Offers
                        </Typography>
                        <Divider variant="middle" />
                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing="12" mt={1}>
                            {offerList.map((row) => (
                                <Grid item key={row.id} ><Offer offerData={row} /></Grid>
                            ))}
                        </Grid>

                    </div>
                </CardContent>
            </Card>


            <Dialog open={openInfo} onClose={handleCloseInfo} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton variant="outlined" onClick={handleCloseInfo}>
                            <FiIcons.FiInfo />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Earn
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Typography id="modal-modal-title" variant="overline" align="center">
                    Check out your personalized offers unique to you!
                    <br /><br />
                    See an offer you like? Make the purchase, save the receipt, click “Earn” & upload.  That’s it.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                        <Button onClick={() => { handleCloseInfo() }}>Done</Button>
                    </ButtonGroup>

                </Box>
            </Dialog>
        </>
    )
}
