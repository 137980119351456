import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getFunctions, connectFunctionsEmulator  } from 'firebase/functions'
import 'firebase/compat/storage'



//Values are saved in environment variables for easier switching between dev/prod
const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
})

export const storage = app.storage()
export const auth = app.auth()
const functions = getFunctions(app)
//connectFunctionsEmulator(functions, "localhost", 5001);
export default app
