import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Components
import Signup from './Signup'
import { Container } from 'react-bootstrap'
import Login from './Login'
import PrivateRoute from './PrivateRoute'
import ForgotPassword from './ForgotPassword'
import CssBaseline from '@mui/material/CssBaseline'
// Contexts
import { DashboardProvider } from '../contexts/DashboardContext'
import { AuthProvider } from '../contexts/AuthContext'
// Pages
import MainPage from '../Pages/Main'

function App() {
    return (
        <>
            <CssBaseline />
            <Router>
                <AuthProvider>
                    <DashboardProvider>
                        <Switch>
                            <PrivateRoute exact path="/" component={MainPage} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/login" component={Login} />
                            <Route
                                path="/forgot-password"
                                component={ForgotPassword}
                            />
                        </Switch>
                    </DashboardProvider>
                </AuthProvider>
            </Router>
        </>
    )
}

export default App
