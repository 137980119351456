//React
import React, { useState, useEffect } from 'react'
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'

//Icons
import * as AiIcons from 'react-icons/ai'

// Contexts
import { useDashboard } from '../contexts/DashboardContext'

// Components
import MyBrands from './MyBrands'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}))

export default function BrandsCampaigns() {
    const { setNavigationComponent, setOpenNavMenu } = useDashboard()

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Toolbar>
                                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                        My Brands
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AiIcons.AiOutlinePlus />}
                                        edge="end"
                                        onClick={() => {
                                            setNavigationComponent('findBrands')
                                        }}
                                    >
                                        Find More Brands
                                    </Button>
                                </Toolbar>
                                <MyBrands />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
