//React
import React from 'react'
//Context
import { useDashboard } from '../contexts/DashboardContext'
//Components
import DashboardListItems from './SidebarListItems'
//MUI
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
//Icons
import * as AiIcons from 'react-icons/ai'

const drawerWidth = 240
const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}))

export default function SideBar() {
    const { toggleDrawer, open } = useDashboard()

    return (
        <>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <AiIcons.AiOutlineMenu />
                    </IconButton>
                </Toolbar>
                <List>
                    <DashboardListItems />
                </List>
            </Drawer>
        </>
    )
}
