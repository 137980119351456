//React
import React, { useEffect } from 'react'
//MUI
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import * as FiIcons from 'react-icons/fi'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';


//Icons

// Components
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useDashboard } from '../contexts/DashboardContext'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyBrands() {

    const { setNavigationComponent, setSelectedBrand, followedBrandsChanged, setFollowedBrandsChanged, followedBrandsJSON, setFollowedBrandsJSON } = useDashboard()

    const [isLoading, setLoading] = React.useState(false)
    const [followed, setFollowed] = React.useState([])

    const [openInfo, setOpenInfo] = React.useState(true);
    const handleOpenInfo = () => {setOpenInfo(true);};
    const handleCloseInfo = () => {setOpenInfo(false);};

    async function fetchBrands() {
        const functions = getFunctions()
        const getBrands = httpsCallable(functions, 'getFollowedBrands')
        setFollowedBrandsJSON([])
        try {
            await getBrands().then((result) => {
                setFollowedBrandsJSON(JSON.parse(result.data))
                setLoading(false)
                setFollowedBrandsChanged(false);
            })
        } catch (error) {
            console.log('Error getting Brands.')
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (followedBrandsChanged) {
            console.log("fetching brands")
            setLoading(true);
            fetchBrands();
        }

    }, [followedBrandsChanged])

    return (
        <>
            <Table size="small">
                <TableBody>
                    {followedBrandsJSON.map((row) => (
                        <TableRow key={row.id}
                            onClick={() => {
                                setSelectedBrand(row);
                                setNavigationComponent("brandDashboard");
                            }}>
                            <TableCell>
                                <img src={row.logoURL} style={{ width: 50, height: 50 }} />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Dialog open={openInfo} onClose={handleCloseInfo} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton variant="outlined" onClick={handleCloseInfo}>
                            <FiIcons.FiInfo />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Home
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Typography id="modal-modal-title" variant="overline" align="center">
                    View your live brands or find others.  Click on one to access the brand account page.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                        <Button onClick={() => { handleCloseInfo() }}>Done</Button>
                    </ButtonGroup>

                </Box>
            </Dialog>
        </>
    )
}
