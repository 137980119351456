//React
import React from 'react'

//MUI
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

//Pages
import Home from '../Pages/Home'
import BrandsCampaigns from '../Pages/BrandsCampaigns'
import FindBrands from '../Pages/FindBrands'
import Settings from '../Pages/Settings'
import Profile from '../Pages/Profile'
import PreSignUpQuestions from '../Pages/PreSignUpQuestions'


//Context
import { useDashboard } from '../contexts/DashboardContext'
import BrandDashboard from '../Pages/BrandDashboard'
import Earn from '../Pages/Earn'
import History from '../Pages/History'
import UploadTest from '../Pages/UploadTest'

export default function MainContent() {
    const { navigationComponent } = useDashboard()
    return (
        <>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3, width: { sm: '100%' } }}
                    >
                        {navigationComponent === 'home' && <BrandsCampaigns />}
                        {navigationComponent === 'findBrands' && <FindBrands />}
                        {navigationComponent === 'brandDashboard' && <BrandDashboard />}
                        {navigationComponent === 'settings' && <Settings />}
                        {navigationComponent === 'profile' && <Profile />}
                        {navigationComponent === 'presignquestions' && <PreSignUpQuestions />}
                        {navigationComponent === 'earn' && <Earn />}
                        {navigationComponent === 'history' && <History />}
                        {navigationComponent === 'uploadTest' && <UploadTest />}
                    </Box>
                </Container>
            </Box>
        </>
    )
}
