//React
import React from 'react'
import { useDashboard } from '../contexts/DashboardContext'
//MUI
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
//Icons
import * as AiIcons from 'react-icons/ai'
import * as FiIcons from 'react-icons/fi'
import * as CgIcons from 'react-icons/cg'
import * as MdIcons from 'react-icons/md'
import * as FaIcons from 'react-icons/fa'

export default function SideBarListItems() {
    const { setNavigationComponent, setOpenNavMenu } = useDashboard()

    return (
        <>
            <ListItem
                button
                key="Home"
                onClick={() => {
                    setNavigationComponent('home')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <AiIcons.AiOutlineHome />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem
                button
                key="RewardsHub"
                onClick={() => {
                    setNavigationComponent('rewardsHub')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <AiIcons.AiOutlineStar />
                </ListItemIcon>
                <ListItemText primary="Rewards Hub" />
            </ListItem>
            <ListItem
                button
                key="Messages"
                onClick={() => {
                    setNavigationComponent('messages')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <FaIcons.FaRegEnvelopeOpen />
                </ListItemIcon>
                <ListItemText primary="Messages" />
            </ListItem>
            <ListItem
                button
                key="Educate"
                onClick={() => {
                    setNavigationComponent('educate')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <MdIcons.MdOutlineSchool />
                </ListItemIcon>
                <ListItemText primary="Educate" />
            </ListItem>
            <ListItem
                button
                key="Activity"
                onClick={() => {
                    setNavigationComponent('activity')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <FiIcons.FiActivity />
                </ListItemIcon>
                <ListItemText primary="Activity" />
            </ListItem>

            <ListItem
                button
                key="Profile"
                onClick={() => {
                    setNavigationComponent('profile')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <CgIcons.CgProfile />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItem>
            <ListItem
                button
                key="Settings"
                onClick={() => {
                    setNavigationComponent('settings')
                    setOpenNavMenu(false)
                }}
            >
                <ListItemIcon>
                    <FiIcons.FiSettings />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItem>
        </>
    )
}
