//React
import React from 'react'
//MUI
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useDashboard } from '../contexts/DashboardContext'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
//Components
import { getFunctions, httpsCallable } from 'firebase/functions'

export default function PreSignUpQuestions() {

    const { selectedBrand, setNavigationComponent } = useDashboard();
    const [ formData, setFormData ] = React.useState({})

    async function submitQuestions() {
        const functions = getFunctions()
        const submitSignupQuestions = httpsCallable(functions, 'submitSignupQuestions')

        var obj = {};
        obj["brandId"] = selectedBrand.id;
        obj["onboardingQuestions"] = formData;
        var json = JSON.stringify(obj);
        console.log(json);

        try {
            await submitSignupQuestions({ requestJSON: json }).then((result) => {
                console.log(result)
                setNavigationComponent('brandDashboard')
            })
        } catch {
            console.log('Error submitting signup questions.')
            setNavigationComponent('brandDashboard')
        }

        setNavigationComponent("brandDashboard");
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,

            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <Card>
                <CardContent>
                <div align="center">
                    <img src={selectedBrand.logoURL} style={{ width: 80, height: 80 }} />
                </div>
                {Object.keys(selectedBrand.onboardingQuestions).map((question) => (
                    <>
                        <TextField name={selectedBrand.onboardingQuestions[question].questionText} id={question} label={selectedBrand.onboardingQuestions[question].questionText} type="text" variant="standard" fullWidth  onChange={handleChange} /><br/><br/>
                    </>
                ))}
                    <Button variant="contained"  className="w-100 " type="submit" onClick={()=> {submitQuestions();}}>
                    Submit
                </Button>
                </CardContent>
            </Card>
        </>
    )
}
