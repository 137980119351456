import React from 'react'

//Material UI
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button'
import { useDashboard } from '../../contexts/DashboardContext'
import AOVOffer from './AOVOffer';
import TBOOffer from './TBOOffer';

export default function Offer({ offerData }) {
    const { setNavigationComponent, setSelectedOffer } = useDashboard()

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    margin: 'auto',
                    width: 320,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            {offerData.category === 'AOV' && <AOVOffer offerData={offerData}/>}
                            {offerData.category === 'TBO' && <TBOOffer offerData={offerData}/>}
                            <Grid item>
                            {offerData.status === 'ACTIVE' &&  <Button variant="text" className="w-100 " type="submit" onClick={() => { setSelectedOffer(offerData); setNavigationComponent("uploadTest"); }}>Earn</Button>}
                            {offerData.status === 'PENDING' &&    <Typography color="gray"variant="body2" gutterBottom>Offer Claimed!</Typography>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}