import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useDashboard } from '../contexts/DashboardContext'

//Material UI
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'


export default function Profile() {
    const [error, setError] = useState('');
    const { currentUser, logout } = useAuth();
    const history = useHistory();
    const {setFollowedBrandsChanged} = useDashboard();

    async function handleLogout() {
        setError('')
        //Make sure that we refresh the cache of followed brands when we log back in.
        setFollowedBrandsChanged(true);
        try {
            
            await logout()
            history.pushState('/login')
        } catch {
            setError('Failed to log out')
        }
    }

   

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h2" align="center">
                        Profile
                    </Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <strong>Email:</strong> {currentUser.email}
                    <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
                        Update Profile
                    </Link>
                </CardContent>
            </Card>
            <div align="center">
               
                <Button variant="text" onClick={handleLogout}>
                    Log Out
                </Button>
            </div>
        </>
    )
}
