//React
import React, { useState } from 'react'
//MUI
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useDashboard } from '../contexts/DashboardContext'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';

import * as FiIcons from 'react-icons/fi'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { storage } from '../firebase'
import { useAuth } from '../contexts/AuthContext'
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'


import { getFunctions, httpsCallable } from 'firebase/functions'

//Date Adapter
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const Input = styled('input')({
    display: 'none',
});

export default function UploadTest() {

    const { selectedBrand, setNavigationComponent, selectedOffer, setSelectedOffer } = useDashboard();
    const { currentUser } = useAuth();

    const [fileName, setFileName] = React.useState('');
    const [fileObject, setFileObject] = React.useState(null);

    const [startDateTime, setStartDateTime] = useState('')
    const [amount, setAmount] = useState(null)
    const [orderNumber, setOrderNumber] = useState('')

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')


    function setFile(e) {
        const uploadFile = e.target.files[0]
        setFileObject(uploadFile);
        setFileName(uploadFile.name);
    }

    function handleSubmit(e) {
        if (startDateTime === '') {
            setError("Please set a purchase date.")
        }
        else if (amount === null) {
            setError("Please set an amount.")
        }
        else if (orderNumber === '') {
            setError("Please set an order number.")
        }
        else if (fileObject === null) {
            setError("Please upload a reciept.")
        }
        else {
            setError(null);

            const uploadTask = storage.ref(`${currentUser.uid}/files/${new Date().getTime()}_${fileObject.name}`).put(fileObject);

            uploadTask.on('state_changed', snapshot => {

            }, () => {

            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    //In here call a new cloud function that updates the db with the info for this reciept upload
                    return processReciept(url);
                })
            })

        }
    }

    async function processReciept(fileUrl) {

        let offerId = null;
        if (selectedOffer == null) {
            offerId = "no-offer"
        }
        else {
            offerId = selectedOffer.id;
        }

        const formJSON = {
            "brandId": selectedBrand.id,
            "formPurchaseDate": startDateTime,
            "formAmount": amount,
            "orderNumber": orderNumber,
            "fileUrl": fileUrl,
            "offerId": offerId
        }

        if (selectedOffer.category === "AOV") {
            console.log("amount" + amount)
            console.log("value" + selectedOffer.value)
            if (Number(amount) >= Number(selectedOffer.value)) {//Success run the upload
                const functions = getFunctions();
                const ProcessRecieptUpload = httpsCallable(functions, 'ProcessRecieptUpload');
                try {
                    await ProcessRecieptUpload({ requestJSON: JSON.stringify(formJSON) })
                        .then((result) => {
                            const ret = JSON.parse(result.data);
                            console.log(ret.responseStatus)
                            if (ret.responseStatus === 'SUCCESS') {
                                setStartDateTime('');
                                setAmount(null);
                                setFileName('');
                                setFileObject(null);
                                updateStats(ret.recieptId, ret.batchId);
                                setSelectedOffer(null);
                            }

                        })
                } catch {
                    setError("ERROR Uploading reciept");
                }
            }
            else{
                console.log("not enough for this AOV offer")
            }
        }
        else if (selectedOffer.category === "TBO") {
            if(selectedOffer.endDate > Date.parse(startDateTime)){ //TODO upload date or purchase date? //Success run
                const functions = getFunctions();
                const ProcessRecieptUpload = httpsCallable(functions, 'ProcessRecieptUpload');
                try {
                    await ProcessRecieptUpload({ requestJSON: JSON.stringify(formJSON) })
                        .then((result) => {
                            const ret = JSON.parse(result.data);
                            console.log(ret.responseStatus)
                            if (ret.responseStatus === 'SUCCESS') {
                                setStartDateTime('');
                                setAmount(null);
                                setFileName('');
                                setFileObject(null);
                                updateStats(ret.recieptId, ret.batchId);
                                setSelectedOffer(null);
                            }

                        })
                } catch {
                    setError("ERROR Uploading reciept");
                }
            }
            else{
                console.log("not enough for this TBO offer")
            }
        }




    }

    async function archiveAndRegenerateOffers() {
        const functions = getFunctions()
        const CreateAllUserOffers = httpsCallable(functions, 'CreateAllUserOffers')
        const ArchiveCurrentOffers = httpsCallable(functions, 'ArchiveCurrentOffers')

        const formJSON = {
            "brandId": selectedBrand.id
        }

        try {
            await ArchiveCurrentOffers({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                    return CreateAllUserOffers({ requestJSON: JSON.stringify(formJSON) }).then((result) => {

                    });
                });
           
        } catch (error) {
            console.log('Error data.')
        }
       
    }

    async function updateStats(recieptId, batchId) {

        const formJSON = {
            "brandId": selectedBrand.id,
            "recieptId": recieptId,
            "batchId": batchId
        }

        const functions = getFunctions();
        const CalculateSummaryData = httpsCallable(functions, 'CalculateSummaryData');
        try {
            await CalculateSummaryData({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    archiveAndRegenerateOffers()
                })
        } catch {
            setError("Updating Stats");
        }
    }


    return (
        <>
            <Card>
                <CardContent>
                    <Toolbar>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setNavigationComponent('brandDashboard')
                            }}
                        >
                            <FiIcons.FiChevronLeft />
                        </IconButton>
                    </Toolbar>
                    <div align="center">
                        {error && <Alert severity="error">{error}</Alert>}
                        {success && <Alert severity="success">{success}</Alert>}
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Show Me the Money
                        </Typography>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker label="Purchase Date" renderInput={(props) => <TextField {...props} />} value={startDateTime} onChange={(newValue) => { setStartDateTime(newValue); }} />
                        </LocalizationProvider>
                        <br />
                        <br />
                        <TextField id="purchaseAmount" label="Purchase Amount" value={amount} type="number" required variant="standard" onChange={e => setAmount(e.target.value)} /><br /><br />
                        <TextField id="orderNumber" label="Order Number" value={orderNumber} required variant="standard" onChange={e => setOrderNumber(e.target.value)} /><br /><br />

                        <label htmlFor="contained-button-file">
                            <Input onChange={setFile} accept="image/*" id="contained-button-file" type="file" />
                            <Button variant="contained" component="span">
                                Upload
                            </Button>
                        </label>
                        <br />
                        {fileName}
                        <br />
                        <Button variant="contained" component="span" onClick={handleSubmit}>
                            Submit
                        </Button>


                    </div>
                </CardContent>
            </Card>
        </>
    )
}
