//React
import React from 'react'
//MUI
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

//Contexts
import { useDashboard } from '../contexts/DashboardContext'

export default function Settings() {
    const { themeMode, setThemeMode } = useDashboard()

    return (
        <>
            <Typography variant="h2" align="center">
                Settings
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={themeMode}
                            onChange={() => {
                                setThemeMode(!themeMode)
                            }}
                        />
                    }
                    label="Dark Mode"
                />
            </FormGroup>
        </>
    )
}
