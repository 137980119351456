import React, { useContext, useState } from 'react'
//MUI
import { createTheme } from '@mui/material/styles'

const DashboardContext = React.createContext()



export function useDashboard() {
    return useContext(DashboardContext)
}

export function DashboardProvider({ children }) {
    //Navigation
    const [navigationComponent, setNavigationComponent] = useState('home')

    //Theme
    const [themeMode, setThemeMode] = useState(true)
    const selectedTheme = createTheme({
        palette: {
            mode: themeMode ? 'dark' : 'light',
        },
    })

    //NavMenu Toggle
    const [isOpenNavMenu, setOpenNavMenu] = React.useState(false)
    const toggleNavMenu = () => {
        setOpenNavMenu(!isOpenNavMenu)
    }

    //Drawer Toggle
    const [open, setOpen] = React.useState(false)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const [selectedBrand, setSelectedBrand] = useState();
    const [selectedOffer, setSelectedOffer] = useState();

    const [followedBrandsChanged, setFollowedBrandsChanged] = useState(true);

    const [followedBrandsJSON, setFollowedBrandsJSON] = React.useState([])

   

    const value = {
        navigationComponent,
        setNavigationComponent,
        themeMode,
        setThemeMode,
        selectedTheme,
        open,
        setOpen,
        toggleDrawer,
        isOpenNavMenu,
        setOpenNavMenu,
        toggleNavMenu,
        setSelectedBrand,
        selectedBrand,
        setSelectedOffer,
        selectedOffer,
        followedBrandsChanged,
        setFollowedBrandsChanged,
        followedBrandsJSON,
        setFollowedBrandsJSON,
    }

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}
