//React
import React, { useEffect } from 'react'
//MUI
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useDashboard } from '../contexts/DashboardContext'
import * as FiIcons from 'react-icons/fi'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup';

//Icons

// Components
import { getFunctions, httpsCallable } from 'firebase/functions'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function History() {

    const { selectedBrand, setNavigationComponent  } = useDashboard()

    const [openInfo, setOpenInfo] = React.useState(true);
    const handleOpenInfo = () => {setOpenInfo(true);};
    const handleCloseInfo = () => {setOpenInfo(false);};

    useEffect(() => {

    }, [])

    return (
        <>
            <Card>
                <CardContent>
                <Toolbar>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setNavigationComponent('brandDashboard')
                            }}
                        >
                            <FiIcons.FiChevronLeft />
                        </IconButton>
                    </Toolbar>
                    <div align="center">
                        <img src={selectedBrand.logoURL} style={{ width: 80, height: 80 }} />
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Zellit History for {selectedBrand.name} 
                        </Typography>

                        <Divider variant="middle" />
                       
                    </div>
                </CardContent>
            </Card>

            <Dialog open={openInfo} onClose={handleCloseInfo} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton variant="outlined" onClick={handleCloseInfo}>
                            <FiIcons.FiInfo />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            History
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Typography id="modal-modal-title" variant="overline" align="center">
                    Check out all your activity with the brand since you joined.  More activity means more money for you!  
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                        <Button onClick={() => { handleCloseInfo() }}>Done</Button>
                    </ButtonGroup>

                </Box>
            </Dialog>
        </>
    )
}
