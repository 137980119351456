//React
import React, { useState, useEffect } from 'react'
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Toolbar from '@mui/material/Toolbar'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'

//Icons
import * as BiIcons from 'react-icons/bi'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as FiIcons from 'react-icons/fi'

// Contexts
import { useDashboard } from '../contexts/DashboardContext'

// Components
import { getFunctions, httpsCallable } from 'firebase/functions'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}))

export default function FindBrands() {
    const [isLoading, setLoading] = React.useState(false)
    const [allBrandsJSON, setAllBrandsJSON] = React.useState([])
    const { setNavigationComponent, setOpenNavMenu, setSelectedBrand, setFollowedBrandsChanged, followedBrandsJSON } = useDashboard()

    async function fetchBrands() {
        const functions = getFunctions()
        const getBrands = httpsCallable(functions, 'getBrands')
        setAllBrandsJSON([])
        try {
            //Get the list of currently followed brands from the cached set in the dashboard context
            var followedIDs = []
            followedBrandsJSON.forEach((element) => {
                followedIDs.push(element['id'])
            })

            await getBrands().then((result) => {
                var allBrands = JSON.parse(result.data)
                setLoading(false)
                allBrands = allBrands.filter((item) => followedIDs.includes(item['id']) == false)
                setAllBrandsJSON(allBrands)
            })
        } catch (error) {
            console.log('Error getting Brands.')
            console.log(error)
            setLoading(false)
        }
    }
    async function followBrand(brandID, brand) {
        const formJSON = {
            brandId: brandID,
        }
        const functions = getFunctions()
        const follow = httpsCallable(functions, 'followBrand')
        
        
       // try {
            await follow({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                const ret = JSON.parse(result.data);
                if(ret.responseStatus === 'FOLLOWED'){
                    setFollowedBrandsChanged(true);
                    createCashOutBatch(brandID);
                    aovOffer(brandID);
                    frequencyOffer(brandID);
                    setNavigationComponent('brandDashboard');
                }
                else if(ret.responseStatus === 'ALREADY_FOLLOWING'){
                    console.log(ret.responseStatus)
                    setNavigationComponent('home');
                }
                
            })
        //} catch {
        //    console.log('Error Following.')
        //    setNavigationComponent('home');
        //}



    }

    async function createCashOutBatch(brandID) {
        const formJSON = {
            brandId: brandID,
        }
        const functions = getFunctions();
        const CreateCashOutBatch = httpsCallable(functions, 'CreateCashOutBatch');
        try {
            await CreateCashOutBatch({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    if(result.data === "PERMISSION-ERROR"){
                        console.log("ERROR");
                    }
                })
        } catch {

        }
    }

    async function aovOffer(brandID) {
        const formJSON = {
            "brandId": brandID,
        }
        const functions = getFunctions();
        const CreateAOVOffer = httpsCallable(functions, 'CreateAOVOffer');
        try {
            await CreateAOVOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    //setResults(result.data);
                })
        } catch {
            //setResults("Error creating AOV offer");
        }
    }

    async function frequencyOffer(brandID) {
        const formJSON = {
            "brandId": brandID,
        }
        const functions = getFunctions();
        const CreateFrequencyOffer = httpsCallable(functions, 'CreateFrequencyOffer');
        try {
            await CreateFrequencyOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    //setResults(result.data);
                })
        } catch {
            //setResults("Error creating frequency offer.");
        }
    }


    useEffect(async () => {
        setLoading(true)
        await fetchBrands()
    }, [])

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Toolbar>
                                    <IconButton
                                        variant="outlined"
                                        onClick={() => {
                                            setNavigationComponent('home')
                                        }}
                                    >
                                        <FiIcons.FiChevronLeft />
                                    </IconButton>
                                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                        Brands You Can Follow
                                    </Typography>
                                    <LoadingButton
                                        loading={isLoading}
                                        variant="outlined"
                                        startIcon={<BiIcons.BiRefresh />}
                                        edge="end"
                                        onClick={() => {
                                            setLoading(true)
                                            fetchBrands()
                                        }}
                                    >
                                        Refresh
                                    </LoadingButton>
                                </Toolbar>
                                <Table size="small">
                                    <TableBody>
                                        {allBrandsJSON.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <img src={row.logoURL} style={{ width: 50, height: 50 }} />
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        id={row.id}
                                                        variant="outlined"
                                                        edge="end"
                                                        onClick={() => {
                                                            console.log('Following: ' + row.id)
                                                            setSelectedBrand(row)
                                                            followBrand(row.id, row)
                                                        }}
                                                    >
                                                        <IoIcons.IoMdAddCircle />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
