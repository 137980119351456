import React from 'react'

//Material UI
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button'
import { useDashboard } from '../../contexts/DashboardContext'

export default function Offer({ offerData }) {
    const { selectedBrand, setNavigationComponent } = useDashboard()

    let d = new Date(offerData.endDate);
    //d.setUTCSeconds(offerData.endDate);

    return (
        <>
        {offerData.status === 'ACTIVE' &&
             <Grid item>
                <Typography gutterBottom variant="h4" component="div">
                    {offerData.rewardAmount*100}% Cash Back
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {offerData.description} by {d.toDateString()}
                </Typography>
            </Grid>
        }
         {offerData.status === 'PENDING' &&
             <Grid item>
                <Typography color="gray" gutterBottom variant="h4" component="div">
                    {offerData.rewardAmount*100}% Cash Back
                </Typography>
                <Typography color="gray" variant="body2" gutterBottom>
                    {offerData.description} by {d.toDateString()}
                </Typography>
            </Grid>
        }
        </>
    )
}