//React
import React, { useEffect } from 'react'
//MUI
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useDashboard } from '../contexts/DashboardContext'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import * as FiIcons from 'react-icons/fi'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';



import { getFunctions, httpsCallable } from 'firebase/functions'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function BrandDashboard() {
    const { selectedBrand, setNavigationComponent } = useDashboard()
    const [amount, setAmount] = React.useState();
    const [pendingAmount, setPendingAmount] = React.useState();
    const [pending, setPending] = React.useState(-1);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (pending === 1) {
            setOpen(true);
        }
        if (pending === 2) {
            setOpenPending(true);
        }
        else {
            return;
        }

    };

    const handleClose = () => { setOpen(false); };

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => { setOpenConfirm(true); };
    const handleCloseConfirm = () => { setOpenConfirm(false); };

    const [openPending, setOpenPending] = React.useState(false);
    const handleOpenPending = () => { setOpenPending(true); };
    const handleClosePending = () => { setOpenPending(false); };

    const [openNotEnough, setOpenNotEnough] = React.useState(false);
    const handleOpenNotEnough = () => { setOpenNotEnough(true); };
    const handleCloseNotEnough = () => { setOpenNotEnough(false); };

    const [openInfo, setOpenInfo] = React.useState(true);
    const handleOpenInfo = () => { setOpenInfo(true); };
    const handleCloseInfo = () => { setOpenInfo(false); };


    async function fetchCashOutAmount() {
        const functions = getFunctions()
        const getCashOutAmount = httpsCallable(functions, 'GetCurrentCashOutBatchData')
        const getPendingBatch = httpsCallable(functions, 'GetPendingCashOutBatchData')

        const formJSON = {
            "brandId": selectedBrand.id
        }

        try {
            await getCashOutAmount({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                const ret = JSON.parse(result.data);
                if (ret.responseStatus === 'SUCCESS') {
                    setAmount(ret.value);
                }
                if (ret.responseStatus === 'MULT_CURRENT') {
                    //console.log("too many")
                }
                if (ret.responseStatus === 'NO_CURRENT') {
                    //console.log("not enough")
                }

                return getPendingBatch({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                    const ret = JSON.parse(result.data);
                    if (ret.responseStatus === 'SUCCESS') {
                        console.log("successfulyl")
                        setPending(2)
                        setPendingAmount(ret.value);
                    }
                    if (ret.responseStatus === 'MULT_PENDING') {
                        console.log("too many")
                    }
                    if (ret.responseStatus === 'NO_PENDING') {
                        setPending(1)
                        console.log("no pending")
                    }
                });
            })
        } catch (error) {
            console.log('Error getting current or pending cash out batch data.');
        }
    }

    async function cashOut() {
        const functions = getFunctions()
        const CashOutCurrentBatch = httpsCallable(functions, 'CashOutCurrentBatch')
        const CreateCashOutBatch = httpsCallable(functions, 'CreateCashOutBatch');

        const formJSON = {
            "brandId": selectedBrand.id
        }

        if (amount < Number(5)) {
            handleClose();
            handleOpenNotEnough();
            return;
        }
        //else{
            try {
                await CashOutCurrentBatch({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                    let response = JSON.parse(result.data);
                    console.log(response);
                    if(response.responseStatus === 'SUCCESS'){
                        return CreateCashOutBatch({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                            if(result.data === "PERMISSION-ERROR"){
                                console.log("ERROR");
                            }
                            else if (result.data === "New Batch Created") {
                                handleClose();
                                handleOpenConfirm();
                                return fetchCashOutAmount();
                            }
                        })
                }
                else if (response.responseStatus === 'PENDING_EXISTS') {
                    handleClose();
                    handleOpenPending();
                }
                else if (response.responseStatus === 'NOT_ENOUGH') {
                    handleClose();
                    handleOpenNotEnough();
                }
            })
        } catch (error) {
            console.log('Error data.')

        }
        // }
    }

    //Test implementation, probably wont live here forever
    async function archive() {
        const functions = getFunctions()
        const ArchiveCurrentOffers = httpsCallable(functions, 'ArchiveCurrentOffers')


        const formJSON = {
            "brandId": selectedBrand.id
        }

        try {
            await ArchiveCurrentOffers({ requestJSON: JSON.stringify(formJSON) }).then((result) => {
                const ret = JSON.parse(result.data);
                    if (ret.responseStatus === 'SUCCESS') {
                        console.log(ret.responseStatus);
                        aovOffer(selectedBrand.id);
                        frequencyOffer(selectedBrand.id)
                    }
            })
        } catch (error) {
            console.log('Error data.')

        }

    }

    async function aovOffer(brandID) {
        const formJSON = {
            "brandId": brandID,
        }
        const functions = getFunctions();
        const CreateAOVOffer = httpsCallable(functions, 'CreateAOVOffer');
        try {
            await CreateAOVOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    //setResults(result.data);
                })
        } catch {
            //setResults("Error creating AOV offer");
        }
    }

    async function frequencyOffer(brandID) {
        const formJSON = {
            "brandId": brandID,
        }
        const functions = getFunctions();
        const CreateFrequencyOffer = httpsCallable(functions, 'CreateFrequencyOffer');
        try {
            await CreateFrequencyOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    //setResults(result.data);
                })
        } catch {
            //setResults("Error creating frequency offer.");
        }
    }



    useEffect(() => {
        fetchCashOutAmount()
    }, [])


    return (
        <>
            <Card>
                <CardContent>
                    <Toolbar>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setNavigationComponent('home')
                            }}
                        >
                            <FiIcons.FiChevronLeft />
                        </IconButton>
                    </Toolbar>
                    <div align="center">
                        <img src={selectedBrand.logoURL} style={{ width: 80, height: 80 }} />
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            {selectedBrand.name} Zellit Dashboard
                        </Typography>


                        <Typography variant="h2" component="div" color="green">
                            ${amount}
                        </Typography>
                        <Typography variant="overline" component="div">
                            Earned Balance
                        </Typography>
                        <Divider variant="middle" />
                        <br />
                        <ButtonGroup orientation="vertical" aria-label="vertical contained button group" variant="text">
                            <Button className="w-100 " type="submit" onClick={() => { setNavigationComponent("earn"); }}>Earn</Button><br />
                            <Button className="w-100 " type="submit" onClick={() => { handleOpen() }}>Redeem</Button><br />
                            <Button className="w-100 " type="submit" onClick={() => { setNavigationComponent("history"); }}>History</Button><br />
                            <Button className="w-100 " type="submit" onClick={() => { archive()}}>Archive Test</Button><br />

                            <Button className="w-100 " type="submit" onClick={() => { test() }}>OFFER TEST</Button><br />

                        </ButtonGroup>
                    </div>


                    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    <FiIcons.FiChevronLeft />
                                </IconButton>
                                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                    Cash Out
                                </Typography>


                            </Toolbar>
                        </AppBar>

                        <Typography id="modal-modal-title" variant="overline" align="center">
                            Do you want to redeem your ${amount} from {selectedBrand.name}?
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                                <Button onClick={() => { cashOut() }}>Confirm</Button>
                            </ButtonGroup>

                        </Box>
                    </Dialog>

                    <Dialog open={openConfirm} onClose={handleCloseConfirm} TransitionComponent={Transition}>
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton variant="outlined" onClick={handleCloseConfirm}>
                                    <FiIcons.FiChevronLeft />
                                </IconButton>
                                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                    Success!
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Typography id="modal-modal-title" variant="overline" align="center">
                            We have sent your request to {selectedBrand.name} and it should be approved in 24 hours.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                                <Button onClick={() => { handleCloseConfirm() }}>Done</Button>
                            </ButtonGroup>

                        </Box>
                    </Dialog>

                    <Dialog open={openPending} onClose={handleClosePending} TransitionComponent={Transition}>
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton variant="outlined" onClick={handleClosePending}>
                                    <FiIcons.FiChevronLeft />
                                </IconButton>
                                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                    Pending
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Typography id="modal-modal-title" variant="overline" align="center">
                            You already have a pending cashout of ${pendingAmount} for {selectedBrand.name}. Please wait until it is approved before cashing out again.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                                <Button onClick={() => { handleClosePending() }}>Done</Button>
                            </ButtonGroup>

                        </Box>
                    </Dialog>


                    <Dialog open={openNotEnough} onClose={handleCloseNotEnough} TransitionComponent={Transition}>
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton variant="outlined" onClick={handleCloseNotEnough}>
                                    <FiIcons.FiChevronLeft />
                                </IconButton>
                                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                    Pending
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Typography id="modal-modal-title" variant="overline" align="center">
                            You need to earn at least $5 before cashing out. You currently have ${amount} for {selectedBrand.name}.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                                <Button onClick={() => { handleCloseNotEnough() }}>Done</Button>
                            </ButtonGroup>

                        </Box>
                    </Dialog>
                </CardContent>
            </Card>

            <Dialog open={openInfo} onClose={handleCloseInfo} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton variant="outlined" onClick={handleCloseInfo}>
                            <FiIcons.FiInfo />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Brand Page
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Typography id="modal-modal-title" variant="overline" align="center">
                    See your rewards in one place, or go Earn more with your exclusive offers.
                    <br /><br />
                    Don’t forget to upload your past receipts to earn value instantly!
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                        <Button onClick={() => { handleCloseInfo() }}>Done</Button>
                    </ButtonGroup>

                </Box>
            </Dialog>
        </>
    )
}
