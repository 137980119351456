//React
import React, { useEffect }  from 'react'
//MUI
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
//Components
import TopBar from '../components/TopBar'
import SideBar from '../components/SideBar'
import MainContent from '../components/MainContent'
import NavMenuModal from '../components/NavMenuModal'
//Context
import { useDashboard } from '../contexts/DashboardContext'

export default function MainPage() {
    //Get components from the DashboardContext
    const { selectedTheme } = useDashboard()

    return (
        <ThemeProvider theme={selectedTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <NavMenuModal />
                <TopBar />
                {/* <SideBar/> */}
                <MainContent />
            </Box>
        </ThemeProvider>
    )
}
